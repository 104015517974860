// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RR from "common/src/RR.bs.js";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Link from "./Link.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Patch from "common/src/Patch.bs.js";
import * as Route from "./Route.bs.js";
import * as React from "react";
import * as Config from "common/src/Config.bs.js";
import * as Global from "common/src/Global.bs.js";
import * as Region from "common/src/Region.bs.js";
import * as Router from "common/src/Router.bs.js";
import * as AdLayout from "./app-core/AdLayout.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import JsCookie from "js-cookie";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ScreenAtom from "common/src/atoms/ScreenAtom.bs.js";
import * as Tc2Summary from "./tc2/Tc2Summary.bs.js";
import * as ApertureCtx from "./ctx/ApertureCtx.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ContextMenu from "./components/ContextMenu.bs.js";
import * as Translation from "common/src/bindings/Translation.bs.js";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as ContentSection from "common/src/ContentSection.bs.js";
import * as LbPlayerSearch from "./leaderboards/LbPlayerSearch.bs.js";
import * as PlayerBookmark from "./player/PlayerBookmark.bs.js";
import * as SnackbarService from "common/src/SnackbarService.bs.js";
import Button from "@mui/material/Button";
import Clear from "@mui/icons-material/Clear";
import Bookmark from "@mui/icons-material/Bookmark";

function extractHomePageComp(base) {
  return {
          comps: base.comps,
          carryUnits: base.carryUnits,
          spatComps: base.spatComps,
          nonSpatComps: base.nonSpatComps,
          spatComps2: base.spatComps2,
          nonSpatComps2: base.nonSpatComps2,
          count: base.count,
          place: base.place,
          top4: base.top4,
          win: base.win,
          itemDeltas: base.itemDeltas,
          generalItems: base.generalItems,
          unitItems: base.unitItems,
          units: base.units,
          augmentSingles: base.augmentSingles,
          levels: base.levels,
          starUnits: base.starUnits,
          unitDeltas: base.unitDeltas,
          traits: base.traits
        };
}

function HomePage$Favorites(Props) {
  var t = Translation.useT(/* Common */0);
  var match = PlayerBookmark.use(undefined);
  var setFavorites = match[1];
  var favorites = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setAnchorEl = match$1[1];
  var match$2 = React.useState(function () {
        return function (param) {
          
        };
      });
  var setOnRemove = match$2[1];
  return React.createElement("div", {
              className: "home-bookmarks grid gap-3 grid-rows-[repeat(auto-fill,52px)] grid-cols-[repeat(auto-fill,minmax(165px,1fr))]"
            }, React.createElement("div", {
                  className: "flex items-center justify-start pl-1"
                }, React.createElement("span", {
                      className: "inline-block text-xl mt-[-3px] pr-[3px]"
                    }, React.createElement(Bookmark, {
                          fontSize: "inherit"
                        })), React.createElement("h4", {
                      className: "text-xl font-medium"
                    }, t("Bookmarks"))), favorites.length !== 0 ? Belt_Array.mapWithIndex(Belt_SortArray.stableSortBy(favorites, (function (b1, b2) {
                          return Caml.caml_string_compare(b1.name, b2.name);
                        })), (function (idx, bm) {
                      return React.createElement(Link.make, {
                                  onContextMenu: (function (e) {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      var target = e.currentTarget;
                                      Curry._1(setAnchorEl, (function (param) {
                                              return Caml_option.some(target);
                                            }));
                                      return Curry._1(setOnRemove, (function (param) {
                                                    return function (param) {
                                                      Curry._1(setFavorites, (function (bookmarks) {
                                                              return Belt_Array.keep(bookmarks, (function (b) {
                                                                            if (b.region !== bm.region || b.name !== bm.name || Caml_obj.caml_notequal(b.tag, bm.tag)) {
                                                                              return true;
                                                                            } else {
                                                                              return Caml_obj.caml_notequal(b.puuid, bm.puuid);
                                                                            }
                                                                          }));
                                                            }));
                                                      var cb = SnackbarService.sub.contents;
                                                      if (cb !== undefined) {
                                                        var tag = bm.tag;
                                                        Curry._1(cb, bm.name + (
                                                              tag !== undefined ? "#" + tag : ""
                                                            ) + " removed from bookmarks.");
                                                      }
                                                      Curry._1(setAnchorEl, (function (param) {
                                                              
                                                            }));
                                                      return Curry._1(setOnRemove, (function (param, param$1) {
                                                                    
                                                                  }));
                                                    };
                                                  }));
                                    }),
                                  route: {
                                    TAG: /* Player */2,
                                    _0: {
                                      region: Region.fromApiName(bm.region),
                                      playerName: bm.name,
                                      tag: bm.tag,
                                      match: undefined
                                    }
                                  },
                                  children: React.createElement(PlayerBookmark.Card.make, {
                                        bookmark: bm
                                      }),
                                  key: String(idx)
                                });
                    })) : React.createElement("div", {
                    className: "col-span-3 flex items-center text-lg text-white2"
                  }, React.createElement(Link.make, {
                        id: "empty-bookmarks-placeholder",
                        route: {
                          TAG: /* Player */2,
                          _0: undefined
                        },
                        children: React.createElement("i", undefined)
                      })), React.createElement(ContextMenu.make, {
                  options: [[
                      "key",
                      match$2[0],
                      React.createElement("div", {
                            className: "flex gap-1"
                          }, React.createElement(Clear, {}), React.createElement("div", undefined, "Remove from bookmarks"))
                    ]],
                  anchorEl: match$1[0],
                  onClose: (function (param) {
                      return Curry._1(setAnchorEl, (function (param) {
                                    
                                  }));
                    })
                }));
}

var make = React.memo(HomePage$Favorites);

var Favorites = {
  make: make
};

function HomePage(Props) {
  var compsStats = Props.compsStats;
  var rankedCount = compsStats[0];
  var t = Translation.useT(/* Common */0);
  var aperture = ApertureCtx.useValue(undefined);
  ScreenAtom.use(undefined);
  Router.use(undefined);
  React.useState(function () {
        return "";
      });
  var match = Region.useRegionSelect(undefined);
  var setRegionSelect = match[1];
  RR.useIsoLayoutEffect0(function (param) {
        Curry._1(setRegionSelect, (function (param) {
                var r = JsCookie.get("player-region");
                if (r !== undefined) {
                  return Region.fromApiName(r);
                } else {
                  return Region.getInitRegion(undefined);
                }
              }));
        
      });
  return React.createElement(AdLayout.make, {
              children: React.createElement("div", {
                    className: "flex flex-col items-center",
                    id: "content-container"
                  }, React.createElement(LbPlayerSearch.make, {}), React.createElement("div", {
                        className: "flex flex-col items-center w-full sm:w-[540px] md:w-[868px] xl:w-[1035px] mt-4"
                      }, React.createElement(ContentSection.make, {
                            className: "w-full",
                            children: React.createElement(make, {})
                          }), React.createElement(ContentSection.make, {
                            className: "w-full mt-8",
                            children: React.createElement("div", {
                                  className: "flex"
                                }, React.createElement("div", {
                                      className: "pr-4 text-xl font-semibold font-montserrat text-secondary-11"
                                    }, "NEW"), React.createElement("div", {
                                      className: "text-lg"
                                    }, React.createElement(Link.make, {
                                          route: {
                                            TAG: /* SetWrapped */4,
                                            _0: 13,
                                            _1: undefined
                                          },
                                          children: "Set 13 Wrapped is here! Check out how you did in Into the Arcane."
                                        })))
                          }), React.createElement(ContentSection.make, {
                            className: "w-[375px] sm:w-[540px] md:w-[868px] xl:w-[818px] mt-8",
                            children: null,
                            noPadding: true
                          }, React.createElement(ContentSection.Header.make, {
                                title: t("Top Comps") + " (" + Patch.render(Config.lastPatch) + ")",
                                rightContent: React.createElement(Link.make, {
                                      route: {
                                        TAG: /* Stats */0,
                                        _0: {
                                          TAG: /* TeamCompositions2 */10,
                                          aperture: aperture
                                        }
                                      },
                                      children: React.createElement(Button, {
                                            classes: {
                                              root: "h-8 w-24"
                                            },
                                            onClick: (function (param) {
                                                
                                              }),
                                            variant: "outlined",
                                            size: "small",
                                            children: t("View All")
                                          })
                                    })
                              }), React.createElement("div", {
                                className: "grid py-2"
                              }, Belt_Array.mapWithIndex(Belt_Array.slice(Belt_SortArray.stableSortBy(Belt_Array.keep(compsStats[1], (function (group) {
                                                  return Global.f(group.count) / Global.f(rankedCount) * 8 > 0.05;
                                                })), (function (g1, g2) {
                                              return Caml.caml_float_compare(g1.place, g2.place);
                                            })), 0, 5), (function (idx, group) {
                                      return React.createElement("div", {
                                                  key: String(idx),
                                                  className: idx % 2 === 1 ? "bg-bg2" : ""
                                                }, React.createElement(Tc2Summary.make, {
                                                      group: group,
                                                      globalCount: rankedCount,
                                                      onExpand: (function (prim) {
                                                          
                                                        })
                                                    }));
                                    }))))))
            });
}

var make$1 = HomePage;

export {
  extractHomePageComp ,
  Favorites ,
  make$1 as make,
  
}
/* make Not a pure module */
